@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');


.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  .logo {
    width: 100%;
    max-width: 400px; /* מגביל את רוחב התמונה במחשב */
    height: auto;
    user-select: none; /* מונע סימון התמונה */
  }
  
  .title {
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap; /* מונע מעבר שורה בכותרת */
    margin-bottom: 10px;
    user-select: none; /* מונע סימון הכותרת */
  }
  
  .description {
    font-size: 1.3rem;
    line-height: 1.5;
    max-width: 500px; /* הגבלת רוחב לטקסט */
    margin: 0;
    user-select: none; /* מונע סימון התיאור */
    font-family: 'Inter', sans-serif;

    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  }
  
  /* התאמות למובייל */
  @media (max-width: 768px) {
    .logo {
      max-width: 220px; /* גודל קטן יותר במובייל */
    }
  
    .title {
      /* display: flex; */
      /* justify-content: center; */
      font-size: 0.7rem; /* גודל פונט קטן יותר במובייל */
      /* max-width: 500px; */

    }
  
    .description {
      font-size: 16px; /* גודל פונט קטן יותר לטקסט בעברית */
      max-width: 100%; /* הגבלת רוחב במובייל */
    }
  }
  
  /* התאמות למחשב */
  @media (min-width: 769px) {
    .section {
      padding: 3%;
    }

    .description {
      /* padding-top: 50%; */
      white-space: nowrap; /* מונע מעבר שורה בטקסט */
      max-width: 100%; /* מאפשר לטקסט להשתמש בכל הרוחב */
    }
  }
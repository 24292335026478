.toRight{
  display: flex;
  justify-content: flex-end;
}

/* .productInfo {
  z-index: 0;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  width: 480px;
  padding: 0 0 80px 12px;
} */

.productInfo {
  z-index: 0;
  display: flex;
  flex-direction: column;
  width: 480px;
  /* padding: 0 0 80px 12px; */
}

/* .productHeader {
  align-self: flex-end;
  display: flex;
  height: 171px;
  width: 444px;
  max-width: 100%;
  flex-direction: column;
  font-family: Inter, sans-serif;
  font-weight: 600;
  justify-content: flex-start;

} */

.productHeader {
  align-self: flex-end;
  display: flex;
  width: 100%; /* מתיחה לרוחב מלא */
  flex-direction: column;
  font-family: Inter, sans-serif;
  font-weight: 600;
  justify-content: flex-start;
}

.productTitle {
  color: #222;
  font-size: 26px;
  line-height: 1.2;
  text-align: right;
}

.productSubtitle {
  display: block;
  font-weight: 400;
  font-size: 12px;
}

/* .productDescription {
  color: #000;
  font-size: 10px;
  font-weight: 400;
  line-height: 21px;
  text-align: right;
  align-self: flex-end;
  margin-top: 12px;
} */

.productDescription {
  direction: rtl; /* מבטיח שהכיוון יהיה ימין לשמאל */
  unicode-bidi: plaintext; /* שומר על הסדר הנכון של השפות */
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: right;
  align-self: flex-end;
  margin-top: 12px;
}

.productPrice {
  /* min-height: 91px; */
  width: 100%;
  font-size: 18px;
  color: #eb7025;
  white-space: nowrap;
  letter-spacing: 0.38px;
  line-height: 36px;
  text-align: right; 

}

.productMeta {
  /* display: flex;
  min-height: 85px;
  width: 100%;
  flex-direction: column;
  color: #616161;
  text-align: right;
  text-transform: uppercase;
  justify-content: flex-start;
  padding: 0 15px;
  font: 400 8px/20px Inter, sans-serif;
  margin: 0; 
  padding: 0; 
  padding-bottom: 8%; */

  display: flex;
  flex-wrap: wrap; /* מאפשר לשבור שורה */
  justify-content: flex-end; /* מיישר לצד ימין */

  min-height: 60px;
  width: 100%;
  color: #616161;
  text-align: right;
  text-transform: uppercase;
  font: 400 8px/20px Inter, sans-serif;
  margin: 0; 
  padding: 0; 
  padding-top: 5%; 
  padding-bottom: 10%; 

}

.productCategory{
  font-size: 9px;
  width: 50%;
  text-align: right; 
  margin: 0; 
  box-sizing: border-box; 
}

@media (max-width: 991px) {
  .productInfo,
  .productHeader,
  .productPrice {
    max-width: 100%;
  }
  
  .productPrice {
    white-space: normal;
  }
}
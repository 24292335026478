.footer {
  background-color: #e7e4df;
  display: flex;
  /* max-width: 390px; */
  flex-direction: column;
  justify-content: flex-start;
  padding: 36px 21px;
  /* width: 100%; */
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #242424;
  text-align: right;
  justify-content: flex-start;
  font: 400 17px/28px Arimo Hebrew Subset, -apple-system, Roboto, Helvetica, sans-serif;
}

.contentItem {
  border-bottom: 1px solid #cdcbc0;
  display: flex;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  padding: 36px 5px;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 21px;
  align-self: stretch;
  margin: auto 0;
}

.text {
  align-self: stretch;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
}

.socialSection {
  display: flex;
  margin-top: 42px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.followUs {
  color: #cdcbc0;
  letter-spacing: 0.27px;
  text-transform: uppercase;
  font: 400 14px Arimo Hebrew Subset, -apple-system, Roboto, Helvetica, sans-serif;
}

.socialIcons {
  display: flex;
  margin-top: 25px;
  width: 100%;
  align-items: flex-start;
  gap: 17px;
  justify-content: flex-start;
}

.socialIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
}

.copyright {
  display: flex;
  margin-top: 42px;
  width: 100%;
  flex-direction: column;
  color: #8b9095;
  text-align: right;
  letter-spacing: 0.23px;
  justify-content: flex-start;
  font: 400 11px Arimo Hebrew Subset, -apple-system, Roboto, Helvetica, sans-serif;
}

.copyrightText {
  width: 100%;
}
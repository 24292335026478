.socialSharing {
    border-top: 1px solid #dee2e6;
    display: flex;
    margin-right: -15px;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    padding: 13px 0;
  }
  
  .shareLabel {
    min-width: 40px;
    color: #777;
    white-space: nowrap;
    flex: 1;
    flex-basis: 80px;
    max-width: 40px;
    padding: 0 0 2px 15px;
    font: 400 10px/19px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .socialIcons {
    display: flex;
    min-width: 240px;
    gap: 12px;
    flex: 1;
    flex-basis: 0%;
    max-width: 474px;
    padding: 4px 48px 4px 15px;
  }
  
  .socialLink {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 2px 2px 0;
  }
  
  .socialIcon {
    width: 11px;
    height: auto;
  }
  
  @media (max-width: 991px) {
    .socialSharing {
      max-width: 100%;
    }
    
    .socialIcons {
      padding-right: 20px;
    }
  }
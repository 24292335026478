/* Arrow buttons for navigation */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Brings arrows above card content */
  transition: background-color 0.3s ease;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 1);
}

.prevArrow {
  left: -2%;
}

.nextArrow {
  right: -2%;
}

/* Showcase section styling */
.showcase {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px;
  background-color: #E7E4DF;
}

.subtitle {
  color: rgba(229, 132, 17, 1);
  letter-spacing: 3.15px;
  text-align: center;
  text-transform: uppercase;
  font: 400 18px Alef, sans-serif;
  user-select: none; /* מונע סימון */


}

.title {
  color: rgba(30, 30, 30, 1);
  text-align: center;
  margin-top: 17px;
  font: 900 31px Inter, sans-serif;
  user-select: none; /* מונע סימון */

}

.carouselContainer {
  width: 100%;
  max-width: 1200px;
  margin-top: 40px;
  padding-bottom: 50px;
}




@media (max-width: 991px) {

  .arrow {
    top: 40%;
  }

  .prevArrow {
    left: -1%;
  }
  
  .nextArrow {
    right: -1%;
  }


}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-slide {
  animation: fade-in 0.5s ease-in-out;
}
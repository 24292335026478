@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.quoteForm {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* ממורכז אנכית */
  font-size: 19px;
  color: #000;
  background-color: #E7E4DF;
}

.formContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formTitle {
  font-size: 1.8vw;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

.formFields{
  width: 70%;
}

.inputGroup {
  width: 100%; 
  margin-bottom: 20px; 
}

.inputField {
  text-align: right;
  direction: rtl;
  width: 100%; 
  padding-top: 10px;  
  padding-bottom: 10px;  
  box-sizing: border-box; 
  border: none; 
  border-radius: 5px; 
  height: 4rem;
  padding-right: 3%;
  font-size: 1rem;
  /* font-family: 'Inter', sans-serif; שימוש בפונט Inter */

}

.inputField::placeholder {
  padding-right: 0%;
  color: #000; /* צבע שחור לטקסט ה-placeholder */
}

.submitButton {
  margin-top: 2rem;
  margin-bottom: 1.7rem;

  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  /* padding-right: 2rem;
  padding-left: 2rem; */

  background-color: transparent;
  color: white;

  border-radius: 14px;
  cursor: pointer;
  box-sizing: border-box; 
  border-color: #000;
  color: black;
  font-size: 1.1rem;
  text-wrap: nowrap;
}


@media (max-width: 768px) {
  .formTitle {
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
    color: #000;
  }
}

.errorMessage {
  color: red;
  margin-top: 10px;
  font-size: 1rem;
  text-align: center;
}
.designerCard {
  width: 100%;
  max-width: 350px;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  padding: 20px;
  background-size: cover;
  background-position: center;
  color: #333;
  padding-top: 300px;
  padding-bottom: 150px;
}

.designerCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  filter: brightness(0.9);
  z-index: 1;
}

.cardContentOne {
  width: 100%;
  height: 100px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.cardContentTwo {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%; 
  max-width: 80%;
  overflow: hidden; 
}

.designerLogo {
  width: 50px;
  height: 50px;
  padding-bottom: 0%;
  display: flex;
  justify-content: end;
  padding-top: 2%;


}

.brandName {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}

.designerDescription {
  font-size: 14px;
  line-height: 1.5;
  margin: 0px;
  padding: 0px;
}
 
.readMore {
  display: flex;
  align-items: center;
  cursor: pointer; 
  transition: background-color 0.3s ease;
  line-height: 1;
  font-weight: 500; /* שומר על משקל אחיד לפני ריחוף */
}

.readMore:hover span {
  background-color: rgba(0, 0, 0, 0.06); /* אפקט רקע בלחיצה */

}

.readMoreIcon {
  width: 50px;
  height: 50px;
}


 /* @media (max-width: 991px) {
  .designerCard {
    width: 100%;
    max-width: 350px;
  }
  .brandName {
    padding-top: 0%;
  }

}

@media (max-width: 420px) {
  .designerCard {
    width: 100%;
    max-width: 300px;
  }
  .brandName {
    margin: 0px;
  }

} */


@media (max-width: 991px) {
  .designerCard {
    width: 80vw;
    max-width: 350px;
  }




}
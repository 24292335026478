
   .sidebar {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: right 0.3s ease-in-out;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    direction: rtl;
    text-align: right;
    overflow-y: auto; /* גלילה בתוך ה-sidebar */
    overscroll-behavior: contain; /* מונע זליגת גלילה */
  }
  
  .sidebarOpen {
    right: 0;
  }
  
  .closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .sidebarMenu {
    list-style: none;
    padding: 0;
    margin-top: 50px;
    direction: rtl;
  }
  
  .sidebarMenu li {
    margin: 20px 0;
    cursor: pointer;
    text-align: right;
    font-size: 18px; /* הגדלת גודל כתב */
    list-style: none; /* מסיר את הנקודות גם ברמת ה-li */

  }
  
  /* מניעת גלילה מוחלטת בעמוד הראשי */
  body.sidebar-active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; /* מניעת גלילה */
    width: 100%;
    height: 100%;
  }
.carouselContainer {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  aspect-ratio: 1.063;
  /* width: 100%; */
  align-items: start;
  /* padding: 12px 28px 12px 14px; */
}

.carouselImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.iconImage {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
}

.scrollBar {
  position: relative;
  display: flex;
  align-items: start;
  gap: 4px;
  overflow: hidden;
  justify-content: center;
  margin: 317px 0 0 13px;
}

.scrollIndicator {
  border-radius: 2px;
  display: flex;
  width: 22px;
  height: 2px;
  flex: 1;
  flex-basis: 0%;
}

.activeIndicator {
  background-color: var(--Neutral-300, #333);
}

.inactiveIndicator {
  background-color: var(--Sand-400, #cdcbc0);
}
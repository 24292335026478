.header {
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
  padding-top: 2%;
  padding-bottom: 2%;
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px; /* Increase space between icons */
  margin-left: 5%; /* Increase space from the edge */
  margin-right: 0%; /* Increase space from the edge */
  background-color: transparent; /* Remove debug colors */
}

.socialLinks:last-of-type {
  margin-left: 0;
  margin-right: 5%;
}

.socialIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px; /* Standard size */
  height: 24px;
  margin: auto 0;
}

.brandContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%; /* Push the logo slightly toward the center */
  padding-right: 10%; /* Push the logo slightly toward the center */
  z-index: 1;
}

.titleWrapper {
  font-size: 20px; /* Adjust logo size */
  font-weight: bold;
  text-align: center;
  color: #000;
}











  .menuContainer {

    display: flex;
    height: 2px;
    width: 24px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .menuLine {
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
  
  .actionsWrapper {

    align-self: stretch;
    display: flex;
    align-items: end;
    gap: 22px;
    justify-content: start;
    width: 23px;
    margin: auto 0;
  }

  .actionButton {
    display: flex;
    width: 23px;
    align-items: center;
    gap: 25px;
    justify-content: end;
    border: none; 
    background: none; 
    outline: none; 
    padding-right: 5; 
}
  
  .actionIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    align-self: stretch;
    margin: auto 0;
  }


  .popup {
    position: absolute;
    top: 60px;
    left: 10%;
    right: 10%;
    z-index: 1000;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .searchContainer {
    margin-bottom: 10px;
  }
  
  .autocomplete {
    width: 100%;
  }
  
  .popupBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .row {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .productImage {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .productName {
    font-size: 14px;
    color: #333;
  }



  
  .logo {
    padding-top: 0.2%;
    width: 80px;
    height: 38px;
    object-fit: contain;
    user-select: none; /* מונע סימון של האלמנט */

    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

  }
body {
  overflow-x: hidden;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #424140;
  padding: 0 2vw; /* padding משני הצדדים */
  box-sizing: border-box; /* לכלול את הפדינג ברוחב הכולל */
  user-select: none;
}

.resultsInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1vw; /* רווח אחיד בין האלמנטים */
}

.leftSection, .rightSection {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.leftSection {
  flex: 1;
}

.rightSection {
  flex: 1;
  justify-content: flex-end;
  overflow: hidden; /* כדי למנוע חיתוך טקסט */
}

.filterButton {
  display: flex;
  align-items: center;
  gap: 0.5vw; 
  color: #fff;
  font: 400 1.5vw Poppins, sans-serif;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.filterIcon, .gridIcon, .listIcon {
  width: 2vw;
  height: auto;
}

.divider {
  width: 2px;
  height: 2.5vw; 
  background-color: #fff;
}

.resultCount {
  font-size: 1.2vw;
  color: #fff;
  white-space: nowrap;
}

.collectionTitle {
  font-size: 2vw;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.45px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* מונע חיתוך ומותאם לרוחב הגמיש */
  padding-left: 1vw; /* מרווח מצד שמאל לטקסט */
}


html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* מניעת גלילה אופקית */
}

.container {
  display: flex;
  flex-direction: column; /* מבטיח שהכותרת תהיה מעל שני האלמנטים */
  align-items: flex-end;
  padding-bottom: 5%;
  user-select: none;
}

.orderTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333;

}

.contentWrapper {
  display: flex;
  justify-content: space-between; /* ריווח בין האלמנטים */
  align-items: flex-start; /* מתחיל מאותו הגובה */
  width: 100%; /* מנצל את כל הרוחב */
  gap: 20px; /* ריווח בין הטבלה והטופס */
  direction: rtl; /* כיווניות מימין לשמאל */
}

.tableContainer {
  flex: 0 0 60%; /* הטבלה תשתמש ב-60% מהרוחב */
  max-width: 60%;
  text-align: right;
  overflow-x: auto; /* מאפשר גלילה אופקית אם הטבלה חורגת */
}


.table {
  width: 100%;
  border-collapse: collapse; /* מסיר רווחים בין תאים */
  border: none; /* מבטל גבול לטבלה */
  max-width: 100%; /* הטבלה לא תחרוג מהקונטיינר שלה */

}

.tableHeaderRow {
  background-color: #E7E4DF;
}

.tableRow:hover {
  background-color: #f5f5f5; /* אפקט ריחוף */
}


/* .cell {
  padding: 12px;
  text-align: right;
  border: none; 
}  */

.cell {
  padding: 12px;
  text-align: right;
  border: none;
  height: 60px; /* גובה קבוע לתאים */
  vertical-align: middle; /* מיקום התוכן באמצע התא */
}


.headerCell {
  text-align: right;
  padding: 12px;
  font-weight: bold;
}


/* .productImage {
  width: 50px;
  height: auto;
} */

.productImage {
  width: 60px; /* רוחב התמונה */
  height: 60px; /* גובה קבוע */
  object-fit: contain; /* התמונה תתאים בתוך הממדים */
  border-radius: 5px; /* אם רוצים עיגול קל לפינות */
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
}

.deleteIcon {
  width: 20px;
  height: 20px;
}

.totalPrice {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  color: #333;
}


.pdfButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.pdfButton:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .tableContainer {
    flex: 0 0 100%; /* הטבלה תשתמש ב-60% מהרוחב */
    max-width: 100%;
    text-align: right;
  }
  .contentWrapper {
    flex-direction: column; /* שינוי לכיוון אנכי */
    align-items: center; /* ממורכז */
    gap: 10px; /* ריווח קטן יותר */
  }
}
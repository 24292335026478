header {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 0.1%;
  padding-bottom: 0.1%;
}

.mainNav {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
}

.socialIcons {
  display: flex;
  align-items: center;
  gap: 17px;
  cursor: pointer;
}

.socialIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 65px;
  /* color: rgb(0, 0, 0); */

  /* color: rgb(255, 255, 255); */
  font: 400 18px Inter, sans-serif;
}

.navLink {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: inherit;
    user-select: none; /* מונע סימון טקסט */
    cursor: pointer;
    text-decoration: none;
  
}

.storeIcon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.logo {
  padding-top: 0.2%;
  width: 80px;
  height: 38px;
  object-fit: contain;
  user-select: none; /* מונע סימון של האלמנט */
  -webkit-user-drag: none; /* מונע גרירת האלמנט */
}

.breadcrumb {
  display: flex;
  gap: 6px;
  color: rgb(34, 34, 34);
  text-align: center;
  margin: 72px 0 0 155px;
  font: 400 10px/2 Inter, sans-serif;
}

.breadcrumb a {
  color: rgb(235, 112, 37);
  text-decoration: none;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 19px;
  margin: 48px 0 0 161px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font: 600 10px/1 Inter, sans-serif;
  color: rgb(34, 34, 34);
}

.backIcon {
  width: 12px;
  height: 12px;
  object-fit: contain;
}

.searchIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;

}

.popup {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 35vw;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.closeButton {
  align-self: flex-start; /* מיושר לימין */
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.searchContainer {
  margin-top: 10px; /* מרווח קטן מתחת לכפתור הסגירה */
  background-color: white;
  border-radius: 5px;
}

.autocomplete {
  width: 100%;
}


.popupBody {
  display: flex;
  flex-direction: column; /* תצוגת עמודה לכל השורות */
  gap: 10px; /* מרווח בין שורות */
}

.row {
  display: flex;
  align-items: center; /* יישור אנכי של כל האלמנטים */
  gap: 15px; /* מרווח בין פרטי המוצר */
  border-bottom: 1px solid #ddd; /* קו תחתון בין השורות */
  padding: 5px 0;
}

.productName {
  flex: 2; /* גמישות גבוהה עבור שם המוצר */
}

.company {
  flex: 1; /* גמישות קטנה יותר עבור שם החברה */
}

.price {
  flex: 1; /* מחיר */
}

.indoor,
.outdoor {
  flex: 1; /* התאמה לפנים/חוץ */
}

.productImage {
  width: 50px; /* גודל התמונה */
  height: 50px;
  object-fit: contain; /* התאמה לתמונה */
}

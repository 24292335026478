.productContainer {
  background-color: #E7E4DF;
  padding-top: 2%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative; 
  z-index: 1;
  user-select: none;
}

.productLayout {
  /* width: 70%; */
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 15px;
}

.productInfoWrapper {
  position: relative;
  display: flex;
  min-width: 240px;
  align-items: flex-start;
  gap: 10px 0;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 510px;
  max-width: 510px;
  padding: 0 15px;
  /* background-color: red; */
  /* margin: 0; */
}



.loaderContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: '#f8f9fa';
  font-size: '18px';
  color: '#333';
}


@media (max-width: 991px) {
  .productInfoWrapper {
    max-width: 100%;
    /* padding-right: 20px; */
  }
}



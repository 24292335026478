.socialMediaContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    width: 133px;
  }
  
  .title {
    color: rgba(246, 151, 63, 1);
    letter-spacing: -0.23px;
    text-align: right;
    font: 400 17px DM Sans, sans-serif;
  }
  
  .iconContainer {
    align-self: flex-end;
    display: flex;
    margin-top: 17px;
    max-width: 100%;
    width: 118px;
    align-items: center;
    gap: 11px;
    justify-content: space-between;
  }
  
  .icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 23px;
    align-self: stretch;
    margin: auto 0;
    cursor: pointer;

  }
.categoryGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 65px;
  justify-content: center;
  align-items: center;
  color: #393939;
  text-align: center;
  font: 600 24px Inter, sans-serif;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 3%;
}

.categoryCard {
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  flex-grow: 1;
  width: 355px;
  border: 2px solid rgba(202, 202, 202, 0.75);
  cursor: pointer; /* Ensures pointer cursor on hover */
  user-select: none; /* Prevents text selection */
  transition: transform 0.2s ease-in-out; /* Smooth hover effect */
}

/* Hover effect for larger screens */
@media (min-width: 992px) {
  .categoryCard:hover {
    transform: scale(1.03); /* Slight zoom effect on hover */
  }
}


.imageWrapper {
  position: relative;
  width: 100%;
  height: 300px; /* הגדרת גובה קבוע */
  display: flex;
  align-items: center;
  justify-content: center;
}

.categoryImage {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  


}

.categoryName {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  color: #393939;
  padding: 10px 20px;
  border-radius: 45px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: auto;
  user-select: none; /* Prevents text selection */
  pointer-events: none; /* Prevents hover interaction with the text */
}

@media (max-width: 991px) {
  .categoryGrid {
    max-width: 100%;
  }

  .categoryCard {
    max-width: 100%;
  }

  .imageWrapper {
    max-width: 100%;
    padding: 0;
    height: 200px; /* הגדרת גובה קבוע */
  }
}



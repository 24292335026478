.productGridContainer {
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: #e7e4df;
  user-select: none;
}

.slider {
  width: 100%;
}

.catalogLink {
  align-self: stretch;
  width: 87px;
  margin: auto 0;
}


.slick-slide > div {
  display: flex;
  justify-content: center;
}



.catalogButton {
  margin-top: 5%;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e58411;
  text-align: right;
  letter-spacing: -0.14px;
  justify-content: flex-start;
  font: 400 14px/24px Arimo Hebrew Subset, -apple-system, Roboto, Helvetica, sans-serif;
  cursor: pointer;
  border: none;
  background-color: #e7e4df;
  width: 100%;
}

.catalogIcon {
  aspect-ratio: 2;
  object-fit: contain;
  object-position: center;
  width: 28px;
  align-self: stretch;
  margin: auto 0;
}

.catalogText {
  align-self: stretch;
  width: 87px;
  margin: auto 0;
}

@media (max-width: 768px) {
  .productGridContainer {
    max-width: 100%;
  }
}
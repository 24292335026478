.popup {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 35vw;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.searchContainer {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
}

.popupBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  cursor: pointer;
}

.productName {
  flex: 2;
}

.productImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
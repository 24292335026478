.wishlistButton {
    background-color: #222;
    border: 1px solid #f5f5f5;
    display: flex;
    min-height: 41px;
    width: 205px;
    align-items: center;
    gap: 15px;
    overflow: hidden;
    justify-content: center;
    padding: 8px 0;
    color: #fff;
    text-align: center;
    font: 600 9px Inter, sans-serif;
    cursor: pointer;
    user-select: none;
  }
  
  .wishlistIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
  }


/* כיסוי למסך */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* תוכן החלון */
.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  text-align: center;

}

/* כפתורים בפופ-אפ */
.modalButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}


.continueButton,
.cartButton {
  padding: 10px 20px;
  border: 1px solid black; /* מסגרת שחורה דקה */
  border-radius: 5px;
  font-size: 16px;
  background-color: white; /* רקע לבן */
  color: black; /* טקסט שחור */
  cursor: pointer;
}

.continueButton:hover,
.cartButton:hover {
  background-color: #f2f2f2; /* רקע אפור קליל בהובר */
}

@media (max-width: 768px) {
  .wishlistButton {
    width: 80%; 
    margin: 0 auto; 
    margin-bottom: 4%;
  }
  .continueButton,
  .cartButton {
    padding: 10px 10px;
  }

  .modalButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }


}
.backLinkContainer {
  width: 90%; /* 70% מרוחב המסך */
  margin: 0 auto; /* מיקום במרכז המסך */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* החץ יהיה בצד השמאלי */
  cursor: pointer; /* מצביע עכבר ללחיצה */
  padding-top: 2%;
}

.backLink {
  display: flex;
  align-items: center;
  gap: 19px;
  justify-content: flex-start;
}

.backIcon {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  justify-content: flex-start;
  width: 12px;
  margin: auto 0;
  padding: 3px 0;
}

.backIconImage {
  aspect-ratio: 1.72;
  object-fit: contain;
  object-position: center;
  width: 12px;
}

.backText {
  color: #222;
  text-align: center;
  align-self: stretch;
  margin: auto 0;
  font: 600 10px/1 Inter, sans-serif;
}


@media(max-width: 991px) {
  .backLinkContainer {
    width: 90%; /* קובע את רוחב הרכיב ל-60% */
    text-align: left; /* מוודא שהטקסט מיושר לשמאל */
  }
}


.productCard {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 400px;
    margin: auto;
  }
  
  .thumbnail {
    width: 100%;
    overflow: hidden;
    align-items: flex-start; /* מוודא שהתמונה תהיה ממוקמת בחלק העליון */

  }
  
  .productImage {
    aspect-ratio: 1.31;
    /* object-fit: contain; */
    object-position: center;
    width: 100%;
    margin: 0;
    object-position: top; /* מיישר את התמונה לחלק העליון */
  }
  

  .artnovaImage{
    object-fit: contain;  
    /* Maybe i will change to cover : consider Tal's opinion */
  } 

  .domkapaImage{
    object-fit: cover;
    object-position: center 50%;
  }

  .talentiImage{
    object-fit: contain;
    object-position: center 50%; /* ממקם את התמונה במרכז אופקי וב-10% מלמעלה */

  }

  .carpaneseImage{
    object-fit: cover;
    object-position: center 50%; /* ממקם את התמונה במרכז אופקי וב-10% מלמעלה */

  }

.paolocasteliImage{
  object-fit: cover;
  object-position: center 50%;
}

.naturedesignImage{
  background-color: red;
  /* object-fit: cover;
  object-position: center 20%; */
  

  /* object-fit: contain; */

}

.laskasasImage{
  object-fit: cover;
  object-position: center 40%;
}

  .defaultImageClass{
    object-fit: cover;

  }

  .productInfo {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0 15px;
  }
  
  .productTitle {
    color: #646464;
    font: 500 19px Syne, sans-serif;
    margin: 0;
    white-space: nowrap; /* מונע שבירת שורות */
    overflow: hidden; /* מסתיר טקסט מעבר לשורה */
    text-overflow: ellipsis; /* מוסיף שלוש נקודות בסוף הטקסט אם הוא נחתך */
    width: 100%; /* מבטיח שהכותרת תשתמש ברוחב הזמין */
  }
  
  .designerName {
    font-weight: 400;
    font-size: 14px;
    color: #646464;
    margin: 0;
  }
  
  .priceRating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 15px 0;
    margin-top: 10px;
  }
  
  .price {
    color: #242424;
    font: 500 24px/1.4 Inter, sans-serif;
  }
  
  .ratingImage {
    width: 37px;
    height: 37px;
    border-radius: 20px;
  }

  .ratingButton {
    background: none; /* מבטל רקע ברירת מחדל */
    border: none; /* מבטל גבול ברירת מחדל */
    padding: 0; /* מבטל ריווח פנימי */
    cursor: pointer; /* מוסיף סמן של כפתור */
    display: inline-flex; /* שומר על התוכן בתוך הכפתור באותו פורמט */
    align-items: center; /* ממרכז את התמונה בתוך הכפתור */
    justify-content: center; /* ממרכז את התמונה בתוך הכפתור */
  }


  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* מבטיח שהפופ-אפ מעל כל האלמנטים */
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    text-align: center;
  }
  
  .modalButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .continueButton,
  .cartButton {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 16px;
    background-color: white;
    color: black;
    cursor: pointer;
  }
  
  .continueButton:hover,
  .cartButton:hover {
    background-color: #f2f2f2;
  }


  .no-scroll {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }


  @media (max-width: 768px) {
     .priceRating {
    padding: 0 5px 5px 0; 
    margin-top: 10px; 
  }

    .ratingImage {
    width: 28px;
    height: 28px;
    border-radius: 20px;
  }

  .price {
    color: #242424;
    font: 500 20px/1.4 Inter, sans-serif;
  }

 
  }


.logoContainer {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    color: rgba(30, 30, 30, 1);
    text-align: right;
    justify-content: flex-start;
    width: 293px;
    font: 700 15px/24px Alef, sans-serif;
  }
  
  .logo {
    aspect-ratio: 2.11;
    object-fit: contain;
    object-position: center;
    width: 80px;
    align-self: flex-end;
  }
  
  .description {
    margin-top: 22px;
  }
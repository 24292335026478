/* .productGridContainer {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding-left: 5%; 
    padding-right: 5%;
    padding-bottom: 5%;
    background-color: #E7E4DF;
  }
  
  .productGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
  }


  @media (max-width: 768px) {
    .productGrid {
      gap: 10px;
    }
    .productGridContainer {
      max-width: 100%;
      width: 100%;
      padding-left: 0%; 
      padding-right: 0%;
    }
    .productGrid > * {
      flex: 0 0 calc(50% - 15px); 
    }
  }
  
  @media (max-width: 420px) {
    .productGrid {
      max-width: 100%;

    }
  }
 */

 .productGridContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding-left: 5%; 
  padding-right: 5%;
  padding-bottom: 5%;
  background-color: #E7E4DF;
  user-select: none;
}

.productGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 10vh;
}


@media (max-width: 768px) {
  .productGrid {
    gap: 10px;
  }
  .productGridContainer {
    max-width: 100%;
    width: 100%;
    padding-left: 0%; 
    padding-right: 0%;
  }
  .productGrid > * {
    flex: 0 0 calc(50% - 15px); 
  }
}

@media (max-width: 420px) {
  .productGrid {
    max-width: 100%;

  }
}


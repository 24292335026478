.hai{
  display: flex; /* שימוש ב-Flexbox */
  justify-content:space-between; /* מפזר את התוכן לקצוות */
  align-items:first baseline; /* ממרכז אנכית */
  width: 100%; /* מבטיח שהכותרת והמחיר יתפסו את כל הרוחב */
}


.productInfo {
  box-sizing: border-box;
    z-index: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    user-select: none;
  }

.productHeader {
  align-self: flex-end;
  display: flex;
  width: 100%; 
  flex-direction: column;
  font-family: Inter, sans-serif;
  font-weight: 600;
  justify-content: flex-start;
}

.productTitle {
  color: #222;
  font-size: 20px;
  line-height: 1.2;
  text-align: right;
  word-wrap: break-word; /* מאפשר לשבור מילים אם צריך */
  min-width: 0; /* הכרחי לתאימות עם flexbox */

}

.productSubtitle {
  display: block;
  font-weight: 400;
  font-size: 12px;
}

.productDescription {
  direction: rtl; 
  unicode-bidi: plaintext; 
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: right;
  align-self: flex-end;
  margin-top: 12px;
}

.productPrice {
  width: 100%;
  font-size: 18px;
  color: #eb7025;
  white-space: nowrap;
  letter-spacing: 0.38px;
  line-height: 36px;
  padding-left: 10%;
  flex-shrink: 0; 


}

/* .productMeta {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #616161;
  text-align: right;
  text-transform: uppercase;
  justify-content: flex-start;
  font: 400 8px/20px Inter, sans-serif;

} */




.productMeta {
  display: flex;
  flex-wrap: wrap; 
  justify-content: flex-end; 
  min-height: 60px;
  width: 100%;
  color: #616161;
  text-align: right;
  text-transform: uppercase;
  font: 400 8px/20px Inter, sans-serif;
  margin: 0; 
  padding: 0; 
  padding-bottom: 1%; 


}

.productCategory{
  font-size: 9px;
  width: 50%;
  text-align: right; 
  margin: 0; 
  box-sizing: border-box; 
}

@media (max-width: 991px) {
  .hai{
    gap: 10px;
  }

  .productInfo,
  .productHeader,
  .productPrice {
    max-width: 100%;
  }
  
  .productPrice {
    white-space: normal;
  }
}
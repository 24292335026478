.container {
    display: flex;
    justify-content: center; /* מרכז את המיכל כולו */
    width: 100%; /* רוחב מלא של המסך */
    font-family: Inter, sans-serif;
    user-select: none;
  }
  
  .heroSection {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 464px;
    width: 100%; 
    /* width: 70%;  */
    max-width: 1200px; 
    align-items: center; /* מרכז את התוכן */
    justify-content: center;
    padding: 111px 0; /* מרחק עליון ותחתון */
    /* background-color: red; */
    z-index: 0; /* תעדוף נכון של האלמנטים */

  }
  
  @media (max-width: 991px) {
    .heroSection {
      min-height: 200px; /* גובה מינימלי למסכים קטנים */
      width: 100% ;
    }
  }
  
  .backgroundImage {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1; /* התמונה ברקע */
  }
  
  .contentBox {
    position: relative;
    background-color: rgba(245, 245, 245, 0.8); /* שקיפות קלה */
    display: flex;
    flex-direction: column;
    padding: 42px 57px;
    z-index: 1; /* התוכן מעל התמונה */
    max-width: 100%;
  }
  
  @media (max-width: 991px) {
    .contentBox {
      padding: 20px;
    }
  }
  
  .title {
    width: 100%;
    padding-bottom: 18px;
    font-size: 26px;
    color: rgba(34, 34, 34, 0.85);
    font-weight: 700;
    line-height: 42px;
  }
  
  .ctaButton {
    background-color: rgba(34, 34, 34, 1);
    border: none;
    align-self: center;
    margin-top: 11px;
    font-size: 11px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    padding: 13px 34px;
    cursor: pointer;
  }
  
  @media (max-width: 991px) {
    .ctaButton {
      padding: 13px 20px;
    }
  }
.ratingButton {
  background: none; /* מבטל רקע ברירת מחדל */
  border: none; /* מבטל גבול ברירת מחדל */
  padding: 0; /* מבטל ריווח פנימי */
  cursor: pointer; /* מוסיף סמן של כפתור */
  display: inline-flex; /* שומר על התוכן בתוך הכפתור באותו פורמט */
  align-items: center; /* ממרכז את התמונה בתוך הכפתור */
  justify-content: center; /* ממרכז את התמונה בתוך הכפתור */
}

.modalOverlay {
  position: fixed; /* מיקום עצמאי על כל המסך */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* רקע כהה */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* מבטיח שהפופ-אפ מעל הכל */
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.modalButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.cartButton,
.continueButton {
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  color: black;
  cursor: pointer;
}

.cartButton:hover,
.continueButton:hover {
  background-color: #f2f2f2;
}

 .productCard {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* width: calc(100% / 3 - 20px);  */
  /* width: calc(100% / 1.1);  */
  /* max-width: 400px; */
  margin: 10px;
}

.thumbnail {
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
}

.productImage {
  aspect-ratio: 1.31;
  object-position: center;
  width: 100%;
  margin: 0;
}

.productInfo {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 15px;
}

.productTitle {
  color: #646464;
  font: 500 19px Syne, sans-serif;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.designerName {
  font-weight: 400;
  font-size: 14px;
  color: #646464;
  margin: 0;
}

.priceRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 15px 0;
  margin-top: 10px;
}

.price {
  color: #242424;
  font: 500 24px/1.4 Inter, sans-serif;
}

.ratingImage {
  width: 37px;
  height: 37px;
  border-radius: 20px;
}

@media (max-width: 1024px) {
  .productCard {
    width: calc(100% / 1.05); 
    /* width: calc(100% / 1.4); שני כרטיסים בשורה */

    /* background-color: red; */
  }
}

@media (max-width: 768px) {
  .productCard {
    width: calc(100% / 1.05); /* שני כרטיסים בשורה */
    /* background-color: yellow; */

  }

  .price {
    font-size: 20px;
  }

  .ratingImage {
    width: 28px;
    height: 28px;
  }
}
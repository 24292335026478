.categoryWrapper {
    display: flex;
    max-width: 84px;
    flex-direction: column;
    color: rgba(33, 41, 48, 1);
    text-align: center;
    padding: 0 5px;
    font: 600 12px Inter, sans-serif;
    cursor: pointer;
    user-select: none; /* מונע סימון של הטקסט */

    
  }
  
  .imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    user-select: none; /* מונע סימון בתוך הקונטיינר */

  }
  
  .categoryImage {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100%;
    border-radius: 38px;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
    background-color: #E7E4DF;
    user-select: none; /* מונע סימון */
    -webkit-user-drag: none; /* מונע גרירת תמונות בדפדפנים מבוססי WebKit */

  }
  
  .categoryTitle {
    align-self: center;
    margin-top: 8px;
    user-select: none; /* מונע סימון של הטקסט */

  }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #333;
  user-select: none;

}

.Title {
  display: flex;
  justify-content: flex-end; /* מציב את הכותרת לימין */
  align-items: center;
  width: 100%;
  padding: 0px 0px; 
}

.collectionTitle {
  font-size: 6.5vw;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.45px;
  text-align: right; /* יישור טקסט לימין */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #333;

}

.filterOption {
  display: flex;
  align-items: center;
  gap: 11px;
  color: #fff;
  font: 400 16px Poppins, sans-serif;
}

.filterIcon {
  width: 32px;
  height: 46px;
  object-fit: contain;
}

.filterText {
  white-space: nowrap;
}

.actionIcons {
  display: flex;
  gap: 15px;
}

.actionIcon {
  width: 32px;
  height: 46px;
  object-fit: contain;
}

/* .footer {
    background-color: rgba(231, 228, 223, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 118px 80px 49px;
  }
  
  .footerContent {
    display: flex;
    width: 100%;
    max-width: 1074px;
    flex-direction: column;
  }
  
  .footerColumns {
    display: flex;
    align-items: flex-start;
    gap: 40px 124px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .footerBottom {
    display: flex;
    margin-top: 56px;
    width: 100%;
    align-items: center;
    gap: 40px 100px;
    font-size: 15px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .termsOfService {
    font-family: Alef, sans-serif;
    color: rgba(30, 30, 30, 1);
    font-weight: 700;
    line-height: 1.6;
  }
  
  .copyright {
    color: rgba(30, 40, 51, 1);
    font-family: DM Sans, sans-serif;
    font-weight: 400;
    letter-spacing: -0.2px;
  }
  
  @media (max-width: 991px) {
    .footer {
      max-width: 100%;
      padding: 100px 20px 0;
    }
  
    .footerContent {
      max-width: 100%;
    }
  
    .footerColumns {
      max-width: 100%;
      margin-right: 10px;
    }
  
    .footerBottom {
      max-width: 100%;
      margin-top: 40px;
    }
  } */

  .footer {
    background-color: rgba(231, 228, 223, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 118px 80px 49px;
    box-sizing: border-box; /* להבטיח שה-padding נלקח בחשבון */
    padding-right: 5%;
    padding-left: 5%;
    user-select: none;

  }
  
  .footerContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1074px;
    box-sizing: border-box;
  }
  
  .footerColumns {
    display: grid; /* שימוש ב-CSS Grid */
    grid-template-columns: repeat(4, 1fr); /* 4 עמודות */
    gap: 40px; /* ריווח בין העמודות */
    width: 100%;
  }
  
  .footerBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 56px;
    width: 100%;
    text-align: center;
    font-size: 15px;
  }
  
  .termsOfService {
    font-family: Alef, sans-serif;
    color: rgba(30, 30, 30, 1);
    font-weight: 700;
    line-height: 1.6;
  }
  
  .copyright {
    color: rgba(30, 40, 51, 1);
    font-family: DM Sans, sans-serif;
    font-weight: 400;
    letter-spacing: -0.2px;
  }
  


  
.breadcrumbContainer {
    display: flex; /* מאפשר מיקום מרכזי */
    justify-content: center; /* ממקם את הרכיב במרכז */
    width: 100%; /* מבטיח שהרכיב יתפוס את כל הרוחב של ההורה */
    padding-top: 2%;
    user-select: none;
  }
  
  .breadcrumb {
    display: flex; /* מציג את הפריטים בשורה אחת */
    align-items: center; /* מוודא שכל הפריטים באותו גובה */
    gap: 6px;
    padding: 0;
    margin: 0;
    list-style: none; /* מסיר עיצוב של מספרים או תבליטים */
    width: 90%; /* קובע את רוחב הרכיב ל-60% */
    text-align: left; /* מוודא שהטקסט מיושר לשמאל */
  }
  
  .link {
    color: #eb7025;
    font-size: 13px;
    line-height: 1;
    text-decoration: none;
  }
  
  .separator {
    font-size: 11px;
    line-height: 1;
  }
  
  .current {
    font-size: 12px;
    line-height: 1;
  }


   /* @media(max-width: 991px) {
    .breadcrumb {
      display: flex; 
      align-items: center; 
      gap: 6px;
      padding-top: 2%;
      margin: 0;
      list-style: none; 
      width: 90%; 
      text-align: left; 
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; 
    }

  } */



  /* @media (max-width: 991px) {
    .breadcrumbContainer {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      overflow: hidden;
    }
  
    .breadcrumb {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 0;
      margin: 0;
      list-style: none;
      width: 80%; 
      overflow: hidden; 
      white-space: nowrap; 
      text-overflow: ellipsis; 
    }
  
    .link, .current {
      display: inline-block;
      overflow: hidden; 
      white-space: nowrap; 
      text-overflow: ellipsis; 
    }
  
    .separator {
      flex-shrink: 0;
    }
  } */


  @media (max-width: 991px) {
  .breadcrumbContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;  
    padding-left: 5%;
    z-index: 0;
  }
  
  .breadcrumb {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 90%; 
    overflow: hidden; 
    white-space: nowrap; 

  }
  
  .link, .current {
    display: inline-block;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 
    max-width: calc(40vw - 20px); 
    vertical-align: middle; 
  }
  .link {
    position: relative;
    z-index: 1;
  }
  .separator {
    flex-shrink: 0;
    margin: 0 4px;
  }
}
.header {
  width: 100%;
  
}

/* .header {
  width: 100%;
  position: fixed;
  background-color: white;
  top: 0;
  z-index: 1000;
  margin: 0;
}



.scrolled {
  position: fixed;
  background-color: white !important;
  color: black !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
} */

 .blackBackground {
  background-color: #e7e4df !important;
  color: black !important;
} 


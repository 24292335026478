/* .column {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: right;
    justify-content: flex-start;
    width: 133px;
  }
  
  .title {
    color: rgba(246, 151, 63, 1);
    letter-spacing: -0.23px;
    font: 400 17px DM Sans, sans-serif;
  }
  
  .linkList {
    display: flex;
    margin-top: 17px;
    width: 100%;
    flex-direction: column;
    color: rgba(30, 30, 30, 1);
    justify-content: flex-start;
    font: 700 15px/1.6 Alef, sans-serif;
    list-style-type: none;
    padding: 0;
  }
  
  .linkItem {
    margin-top: 5px;
  } */
   
  .footerColumn {
    /* margin: 10px; */
    direction: rtl; /* הגדרת כיוון מימין לשמאל */
    text-align: right; /* יישור הטקסט לימין */
}
  
.title {
    color: rgba(246, 151, 63, 1);
    letter-spacing: -0.23px;
    text-align: right; /* יישור הכותרת לימין */
    font: 400 17px DM Sans, sans-serif;
}

.links {
    list-style: none;
    padding: 0;
    margin: 0;
    direction: rtl; /* כיוון מימין לשמאל */
}

.linkItem {
    margin-bottom: 8px;
    text-align: right; /* יישור הפריטים לימין */
}

.linkText {
    text-decoration: none;
    color: #333; /* צבע טקסט */
    transition: color 0.3s;
    display: inline-block;
    text-align: right; /* יישור הטקסט לימין */
}
  
.linkText:hover {
    color: #007bff; /* צבע כשעוברים עם העכבר */
}


.productPageMobile {
    background-color: #e7e4df;
    display: flex;
    /* max-width: 480px; */
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    overflow-x: hidden; /* חוסם גלילה אופקית */
  }
  
  .productShowcase {
    width: 100%;
    height: 600px;

  }

  .productShowcaseContainer {
    display: flex; /* שימוש ב-Flexbox */
    justify-content: center; /* מרכז אופקית */
    align-items: center; /* מרכז אנכית */
    width: 100%;
    height: 400px; /* גובה קבוע לאזור האדום */
    overflow: hidden; /* חוסם גלישה מחוץ לקונטיינר */
    background-color: #e7e4df; /* צבע רקע לבדיקה */
    position: relative; /* מאפשר יישור מדויק של התמונה */
}

.productShowcase {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
  object-position: center center; 
}



  
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: 100%;
    padding-bottom: 70vh;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  } 
  
  .dropdownContainer {
    margin: 10px 0;
    text-align: center;
  }
  
  .selectContainer {
    position: relative;
    display: inline-block;
    width: auto;
    min-width: 150px;
  }
  
  select {
    padding: 5px 30px 5px 5px;
    font-size: 1rem;
    text-align: center;
    text-align-last: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    appearance: none;
    width: 100%;
    cursor: pointer;
  }
  
  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #666;
    pointer-events: none;
  }
  
  .pdf {
    width: 90%;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  canvas.react-pdf__Page__canvas {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    max-height: 80vh; /* גובה מקסימלי: 80% מגובה המסך */

  }
  .navigation {
    position: fixed; /* מיקום קבוע */
    bottom: 0; /* צמוד לתחתית המסך */
    left: 50%; /* מרכז המסך */
    transform: translateX(-50%); /* יישור מרכזי */
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-between; /* ריווח אחיד */
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px 5px 0 0; /* פינות מעוגלות מעל */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* תמיד מעל ה-PDF */
  }
  
  .navButton {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .navButton:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .pageInfo {
    font-size: 1rem;
    color: #333;
  }

  
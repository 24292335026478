
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Brings arrows above card content */
  transition: background-color 0.3s ease;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 1);
}

.prevArrow {
  left: -1%;
}

.nextArrow {
  right: -1%;
}

.imageGallery {
  display: flex;
  min-width: 240px;
  /* width: 480px; */
  padding-bottom: 23px;
  flex-direction: column;
  align-items: center;
}


.mainImageWrapper {
  display: flex;
  max-width: 100%;
  width: 480px;
  flex-direction: column;
  overflow: hidden;

}

.mainImage {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;


}

.mainImage.visible {
  opacity: 1;
}

.thumbnailList {
  display: flex;
  margin-top: 15px;
  width: 100%;
  max-width: 495px;
  align-items: flex-start;
  overflow: hidden;
  justify-content: flex-start;
}

/* .thumbnailTrack {
  display: flex;
  min-width: 240px;
  width: 493px;
  align-items: flex-start;
  gap: 15px;
  justify-content: flex-start;
  flex-wrap: wrap;
} */

.thumbnailTrack {
  display: flex;
  align-items: center; /* ממרכז את התמונות אנכית */
  justify-content: center; /* ממרכז את התמונות אופקית */
  gap: 15px; /* רווחים בין התמונות */
  padding: 0; /* מסיר רווחים מיותרים */
  width: 100%; /* מבטיח שהתמונות יתפסו את כל הרוחב */
}

.thumbnail {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 108px;
  max-width: 109px;
}

/* @media (max-width: 991px) {
  .imageGallery,
  .thumbnailList,
  .thumbnailTrack {
    max-width:100%;
    background-color: red;
  }
} */